import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import LoadingImage from "../../assets/image/loading.png";
import "../EmailResponse.css";

function LoadingScreen() {
  return (
    <div className="text-center font-bold mt-[45vh] text-2xl">
      <img className="w-20 mx-auto mb-3" src={LoadingImage} alt="" />
      <h1>Loading...</h1>
    </div>
  );
}

function EmailAdminResponse(props) {
  const [isSuccess, setIsSuccesss] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const key = query.get("key");

  const conditionalTemplate = () => {
    const handleBackRegister = () => {
      window.location.replace(`${process.env.REACT_APP_REDIRECT_ADMIN}v2/login`)
    }

    
    if (isSuccess === true) {
      return (
        <div className="flex justify-center items-center h-[90vh]">
          <div className="w-[700px]">
            <div className="pb-3 flex justify-between">
              <img src="../../logo-black-teks.png" alt="" />
            </div>
            <div className="w-full py-10 bg-white px-auto rounded">
              <img className="mx-auto" src="../../verification_succees.svg" alt="" />
              <div className="text-[28px] neutral-900 font-bold mt-6 text-center">Email verification success</div>
              <div className="mt-5 px-10 text-sm neutral-700 text-center">Congratulations! you have been successfuly  verified. Go to login page to explore our product.</div>
              <div className="mt-8 mb-12 text-center text-sm">
                <button onClick={handleBackRegister} type="submit" className="h-12 w-[146px] bg-primary-500 hover:!bg-[#006098] active:!bg-[#004B77] text-white rounded font-semibold">Go to login page</button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center items-center h-[90vh]">
          <div className="w-[760px]">
            <div className="pb-3 flex justify-between">
              <img src="../../logo-black-teks.png" alt="" />
            </div>
            <div className="w-full py-10 bg-white px-auto rounded">
              <img className="mx-auto" src="../../verification_expired.svg" alt="" />
              <div className="text-[28px] neutral-900 font-bold mt-6 text-center">Email verification link expired</div>
              <div className="mt-5 px-20 text-sm neutral-700 text-center">Looks like the verification link has expired. Not to worry, we can send the link again by clicking button below</div>
              <div className="mt-8 mb-12 text-center text-sm">
                <button type="submit" className="h-12 w-[194px] bg-primary-500 hover:!bg-[#006098] active:!bg-[#004B77] text-white rounded font-semibold">Resend Verification Link</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const validateData = async () => {
    let formData = new FormData();

    // const dataSave = {
    //   EmailKey: key,
    // } 

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}access/v2/admin/security/verify-email?key=${key}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setIsSuccesss(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsSuccesss(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (key) {
      validateData();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          {conditionalTemplate()}
        </div>
      )}
    </>
  );
}

export default withTranslation()(EmailAdminResponse);
