import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React from 'react';
import EmailResponseV1 from './pages/v1/EmailResponse'
import EmailResponseV2 from './pages/v2/EmailResponse'
import EmailPlatform from './pages/v2/EmailPlatform'
import EmailAdminResponse from './pages/v2/EmailAdminResponse';
import EmailProviderResponse from './pages/v2/EmailProviderResponse';

function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            exact
            path="/v1/activate"
            name="Email Response"
            render={props => <EmailResponseV1 {...props} />}
          />
          <Route
            exact
            path="/v2/activate"
            name="Email Response"
            render={props => <EmailResponseV2 {...props} />}
          />
          <Route
            exact
            path="/v2/platform/activate"
            name="Email Platform Response"
            render={props => <EmailPlatform {...props} />}
          />
          <Route
            exact
            path="/v2/admin/activate"
            name="Email admin Response"
            render={props => <EmailAdminResponse {...props} />}
          />
          <Route
            exact
            path="/v2/merchant/activate"
            name="Email Provider Response"
            render={props => <EmailProviderResponse {...props} />}
          />
          {/* <Redirect from="/" to="/v1/activate" /> */}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;