import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import LoadingImage from "../../assets/image/loading.png";
import "../EmailResponse.css";

function LoadingScreen() {
  return (
    <div className="text-center font-bold mt-[45vh] text-2xl">
      <img className="w-20 mx-auto mb-3" src={LoadingImage} alt="" />
      <h1>Loading...</h1>
    </div>
  );
}

function EmailResponse(props) {
  const [isSuccess, setIsSuccesss] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const key = query.get("key");

  const conditionalTemplate = () => {

    const handleBackRegister = () => {
      window.location.replace(`${process.env.REACT_APP_REDIRECT}/client/v1/auth/login`)
    }

    
    if (isSuccess === true) {
      return (
        <div className="flex justify-center items-center h-[90vh]">
          <div className="w-[700px]">
            <div className="pb-3 flex justify-between">
              <img src="../logo-black-teks.png"  alt=""/>
            </div>
            <div className="w-full py-10 bg-white px-auto rounded">
              <img className="mx-auto" src="../verification_succees.svg"  alt=""/>
              <div className="text-3xl font-semibold mt-6 text-center">Email verification success</div>
              <div className="mt-3 text-sm text-[#585F65] text-center">You've successfully verifying your account. Go to login page to explore our product.</div>
              <div className="mt-8 mb-12 text-center text-sm">
                <button onClick={() => handleBackRegister("login")} type="submit" className="px-3 py-2 bg-[#F7B500] text-white rounded font-medium">Go to login page</button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center items-center h-[90vh]">
          <div className="w-[760px]">
            <div className="pb-3 flex justify-between">
              <img src="../logo-black-teks.png"  alt=""/>
            </div>
            <div className="w-full py-10 bg-white px-auto rounded">
              <img className="mx-auto" src="../verification_expired.svg"  alt=""/>
              <div className="text-3xl font-semibold mt-6 text-center">Email verification link expired</div>
              <div className="mt-3 text-sm text-[#585F65] text-center">Looks like the verification link has expired. Not to worry, we can send the link again by clicking button below</div>
              <div className="text-sm text-[#585F65] text-center">clicking button below</div>
              <div className="mt-8 mb-12 text-center text-sm">
                <button onClick={() => handleBackRegister("register")} type="submit" className="px-3 py-2 bg-[#F7B500] text-white rounded font-medium">Go to register page</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const validateData = async () => {
    let formData = new FormData();
    formData.append("EmailKey", key);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}access/email/validation`,
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 202) {
          setIsSuccesss(true);
        } else {
          setIsSuccesss(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsSuccesss(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (key) {
      validateData();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          {conditionalTemplate()}
        </div>
      )}
    </>
  );
}

export default withTranslation()(EmailResponse);
